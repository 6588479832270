<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-hoinarim">
        <img src="@/assets/casestudy/hoinarim-logo.svg" alt="Hoinarim">
        <span class="sep"></span>
        <p>Hoinarim aims to give you complete informations based on clients reviews for helping you to take the best decision regarding the locations that you wish to visit especiall when you are new
          in town. You will get precise directions based on google maps location and detailed menu, reviews and contact information about restaurants.</p></div>
    </div>

    <div class="laptop-img hoinarim-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/hoinarim-main-img.png" alt="Hoinarim">
      </div>
    </div>

    <div class="container-about hoinarim-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/hoinarim-diamond.svg" alt="Hoinarim">
        <h3>About the project</h3>
        <p>Not all restaurants have an internet presence, when you are visiting a new city you tend to get ‘trapped’ and end-up visiting the most convenient places.<br/>
          The Hoinarim app comes to inform tourists of the available locations, with real customer ratings.<br/>
          It also gives the premium account restaurant owners nice landing page in which they can add their events/menus.</p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-typography__content">
          <h3>Typography</h3>
          <div class="font-typo">
            <img class="doublefont" src="@/assets/casestudy/roboto-font.svg" alt="Hoinarim Main Font">
            <h3><span style="color:#2786ae">Co</span><span style="color:#f3f3f3">lo</span><span style="color:#7c7c7c">rs</span></h3>

            <div class="colors">
              <div class="fcolor hoinarim-p">Primary</div>
              <div class="scolor hoinarim-s">Secondary</div>
            </div>

            <span class="sep vertical color-tag"></span>
            <div class="color-codes cc-hoinarim">
              <div class="ccodes p-hoinarim">
                <p class="primary-h">#2786ae</p>
                <p class="secondary-h">#cecece</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <testimonial backgroundTsColor="#2786ae" tsTitle="#fafafa">
      <div class="testimonial-text">
        <p class="testimonial-title-text">Ciprian Serban, CEO/Founder</p>
        <p class="testimonial-text__content">“Best team I ever worked with. Reliability, punctuality, brilliance and innovation are just a few words describing my experience with Scriptics. I recommend to any CEO or business who likes to get things done in the most professional way. Thanks Vali, thanks team.”</p>
      </div>
    </testimonial>


    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name: 'Vonino'}" class="left">
              <img src="@/assets/casestudy/btn-vonino.jpg" alt="Vonino">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'Creeds'}" class="right">
              <img src="@/assets/casestudy/btn-creeds.jpg" alt="Creeds">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ContactUs color="#2786ae"></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/Home/Footer";
import ContactUs from "../../components/ContactUs";
import Testimonial from "../../components/Testimonial";
import LetsChat from "../../components/LetsChat";

export default {
  name: "Hoinarim",
  components: {LetsChat, Testimonial, ContactUs, Footer,}
}
</script>

<style lang="scss">
.logo-hoinarim img {
  max-width: 320px;
}

.hoinarim-laptop, {
  background: #2786ae;
}
.hoinarim-title {
  color: #FFFFFF;
}
.hoinarim-about {
  background: #f0f0f0;
}
.hoinarim-p {
  background: #2786ae;
}
.primary-h {
  color: #2786ae;
}
.hoinarim-s {
  background: #cecece;
}
.secondary-h {
  color: #cecece;
}
.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #2786ae 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>